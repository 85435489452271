import React from 'react';
import { Seo } from '@app/components';
import {
  Footer,
  Header
} from '@app/components/template';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import blogBack from '@app/images/blog-back.jpg';
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl';

export default (props: any) => {
  const intl = useIntl();
  const postList = props.data.allMarkdownRemark.edges
    .map((edge: any) => edge.node.frontmatter)
    .filter((frontmatter: any) => frontmatter.language === intl.locale)
    .map((frontmatter: any) => ({
      path: frontmatter.path,
      title: frontmatter.title,
      date: frontmatter.date,
      image: frontmatter.image.childImageSharp.fluid
    }));

  return (
    <>
      <Seo title={ intl.formatMessage({ id: 'seo_title_blog' }) }
           description={ intl.formatMessage({ id: 'seo_description_first' }) } />
      <div id="main" className="main-content-wraper">
        <div className="main-content-inner">
          <Header />

          <section className="breadcrumb-section"
                   style={ {
                     backgroundImage: `url(${ blogBack })`,
                     backgroundSize: 'cover',
                     backgroundPosition: 'center top',
                     backgroundRepeat: 'no-repeat'
                   } }>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="text-center breadcrumb-content">
                    <h2>
                      <FormattedMessage id="blog_title" />
                    </h2>
                    <ul className="breadcrumb">
                      <li>
                        <Link to='/'>
                          <FormattedMessage id="footer_home" />
                        </Link>
                      </li>
                      <li>
                        <FormattedMessage id="footer_blog" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="blog-article-section bg-secondary pt-60 pb-30">
            <div className="blog-article-wrap">
              <div className="container">
                <div className="row">
                  {
                    postList.map((post: any, i: any) => (
                      <div className="col-md-4 col-sm-6" key={ i }>
                        <div className="single-blog-article mb-30">
                          <div className="blog-post-img-thumb">
                            <Link to={ post.path }>
                              <Img fluid={ post.image }
                                   alt={ post.title } />
                            </Link>
                          </div>
                          <div className="blog-post-content-area">
                            <h3>
                              <Link to={ post.path }>{ post.title }</Link>
                            </h3>
                            <div className="blog-footer-area">
                              <Link to={ post.path } className="readmore-btn">
                                <FormattedMessage id="index_blog_read_more" />
                                <i className="fa fa-angle-right"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </div>
      </div>
    </>
  );
}

export const query = graphql`
    {
        allMarkdownRemark {
            edges {
                node {
                    excerpt(pruneLength: 250)
                    frontmatter {
                        path
                        date
                        title
                        language
                        image {
                            childImageSharp {
                                resize(width: 1500, height: 1500) {
                                    src
                                }
                                fluid(maxWidth: 786) {
                                    src
                                    srcSet
                                    sizes
                                    aspectRatio
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
